import React from 'react'
import './style.scss'
//import NaviContext from '../../context/NaviContext'
import { useStaticQuery, graphql } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faGithub, faFacebookSquare, faYoutube, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

function SocialIcons(props) {
  //const naviContext = useContext(NaviContext)

  const data = useStaticQuery(graphql`
		query {
      sanitySiteSettings {
        facebook
        instagram
      }
		}
	`)
  const settings = data?.sanitySiteSettings

  const facebook = settings?.facebook ? <div className={`social-icon hint--rounded`}>
    <a href={settings?.facebook} target='_blank' rel='noopener noreferrer'>
      <FontAwesomeIcon icon={faFacebookSquare} />
    </a>
  </div> : null

  const instagram = settings?.instagram ? <div className={`social-icon hint--rounded`}>
    <a href={settings?.instagram} target='_blank' rel='noopener noreferrer'>
      <FontAwesomeIcon icon={faInstagramSquare} />
    </a>
  </div> : null

  // const icons = data?.wp?.options?.acfOptions?.socialIcons?.map((node, i) => {
  //   let icon = node.icon === 'facebook' ? <FontAwesomeIcon icon={faFacebookSquare} /> :
  //     node.icon === 'twitter' ? <FontAwesomeIcon icon={faTwitter} /> :
  //       node.icon === 'youtube' ? <FontAwesomeIcon icon={faYoutube} /> :
  //         node.icon === 'instagram' ? <FontAwesomeIcon icon={faInstagram} /> :
  //           node.icon === 'git' ? <FontAwesomeIcon icon={faGithub} /> :
  //             null
  //   return (
  //     <div key={i} className={`social-icon hint--rounded ${props.hint ? 'hint--' + props.hint : 'hint--bottom'}`} data-hint={node.hint}>
  //       <a href={node.link} target='_blank' rel='noopener noreferrer'>
  //         {icon}
  //       </a>
  //     </div>
  //   )
  // })


  return (
    <div className={`social-icons ${props.classes}`}>
      {facebook}
      {instagram}
    </div>
  )
}

export default SocialIcons